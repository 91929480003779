/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RequestTabPropType } from "./types";

const initialStateValue: RequestTabPropType = {
  request: {
    address: "",
    city_id: 0,
    country_id: 0,
    payment_method: "",
    quantity: 1,
    state_id: 0,
    transaction_id: "",
    amount:"",
  },
  tabData: [],
  tabDataLoading: true,
  tabDetailsNGN: {
    amount: 120000,
    delivery: 5000,
    tax: (120000 * 7.5) / 100,
  },
  tabDetailsUSD: {
    amount: 150,
    delivery: 45,
    tax: (150 * 7.5) / 100,
  },
  tabletLoading: true,
  tablet: {
    id: 0,
    tab_name: "",
    tab_description: "",
    tab_image: "",
    memory_size: 0,
    storage_size: 0,
    battery_size: 0,
    camera_size: 0,
    price: 0,
    is_available: 0,
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
};

export const requesttabSlice = createSlice({
  name: "requesttab",
  initialState: { value: initialStateValue },
  reducers: {
    setRequestTab: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetRequestTab: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setRequestTab, resetRequestTab } = requesttabSlice.actions;

export default requesttabSlice.reducer;
